import { getNextSpecimenOrder } from "./helpers";
import store from "@/store";
import { CasesApi, IcdRulesApi, MacrosApi, SpecimensApi } from "@/services";
import { mergeSpecimensWithMacros } from "./mergeSpecimensWithMacros";
import { uniqBy, unset } from "lodash";
import defaultSpecimen from "./specimen";
import moment from "moment";
import { handleMissingCptPairs } from "./handleMissingCptPairs";

export default async function (prefix, caseDetails) {
  if (!store.getters.permissions?.CaseFinalizeSlidePrep) {
    window.alert("User does not have permission to finalize cases.");
    return;
  }
  const { site, resultsMacroId, pathologistId, protocolId } = prefix;
  const { SpecimenNumberingTypes } = store.state.labSettings;
  try {
    if (Array.isArray(caseDetails.pathologists)) {
      if (pathologistId) {
        const currentPathologistIndex = caseDetails.pathologists.findIndex(
          e => e.id === pathologistId
        );
        caseDetails.pathologists = caseDetails.pathologists.map(e => ({ ...e, isPrimary: false }));
        if (currentPathologistIndex > -1) {
          caseDetails.pathologists[currentPathologistIndex].isPrimary = true;
        } else {
          caseDetails.pathologists = uniqBy(
            [...caseDetails.pathologists, { id: pathologistId, isPrimary: true }],
            "id"
          );
        }
      } else if (!caseDetails.pathologists.find(e => e.isPrimary)) {
        throw new Error("No primary pathologist found.");
      }
    } else {
      caseDetails.pathologists = [{ id: pathologistId, isPrimary: true }];
    }
    if (!caseDetails.specimens?.length) {
      let specimen = {
        ...defaultSpecimen,
        site,
        protocolId,
        caseId: caseDetails.caseId
      };
      specimen.specimenOrder = getNextSpecimenOrder([], SpecimenNumberingTypes);
      specimen = await SpecimensApi.createSpecimen(specimen);
      handleMissingCptPairs(specimen);
      if (specimen.primaryPathologistId === 0) {
        unset(specimen, "primaryPathologistId");
      }
      if (specimen.primaryProviderId === 0) {
        unset(specimen, "primaryProviderId");
      }
      caseDetails.specimens = [specimen];
    }
    if (resultsMacroId) {
      const macro = await MacrosApi.getMacroDetails(resultsMacroId);
      caseDetails.specimens = await mergeSpecimensWithMacros(
        caseDetails.specimens,
        [macro],
        `.${macro.macroName}`
      );
      for (const specimen of caseDetails.specimens) {
        if (!specimen.site) {
          specimen.site = site;
        }
        if (!specimen.protocolId) {
          specimen.protocolId = protocolId;
        }

        const icdEngineCalls = await Promise.all(
          macro.icdCodes.map(async code => {
            if (
              moment(code.expiryOn).isBefore(new Date()) ||
              moment(code.effectiveOn).isAfter(new Date())
            ) {
              window.notify(
                `Icd code outside of effective range found ${code.displayCode}.`,
                "warning"
              );
            }
            const response = await IcdRulesApi.findBillableCode({
              caseReceivedOn: caseDetails.receivedOn,
              searchFor: code.displayCode.replace(/\.([0-9]+)/gi, ""),
              specimenDiagnosis: specimen.diagnosis,
              specimenSite: specimen.site ?? ""
            });
            return [response, code];
          })
        );
        icdEngineCalls.forEach(([response, code]) => {
          const nonBillableCodes = [];
          let newCodes = macro.icdCodes;
          if ([3, 4, 6, 3, 5, 7, 8].includes(response.detail) && !code.isBillable) {
            nonBillableCodes.push(code.displayCode);
          } else if ([2].includes(response.detail)) {
            const { replacementIcdCode } = response;
            newCodes = newCodes.map(icd => {
              if (icd.id === code.id && replacementIcdCode !== icd.id) {
                return replacementIcdCode;
              }
              return icd;
            });
          }
          if (Array.isArray(specimen.icdCodes)) {
            specimen.icdCodes = uniqBy([...specimen.icdCodes, ...newCodes], "id");
          } else {
            specimen.icdCodes = newCodes;
          }
        });
      }
    }
    caseDetails.specimens = caseDetails.specimens.map(e => {
      return {
        ...e,
        contact: []
      };
    });
    const updatedSpecimens = await SpecimensApi.updateSpecimen(caseDetails.specimens);
    handleMissingCptPairs(updatedSpecimens);
    await CasesApi.updateCase(caseDetails);
    const labLocationId = store.state.currentLabLocation || store.state.availableLabLocations[0].id;

    await CasesApi.signCase(caseDetails.caseId, labLocationId);
    //!! Add logic to sign the case so the backend can distribute it.
  } catch (error) {
    window.alert("Error during case completion mode, please contact an administrator.");
  }
  return caseDetails;
}
