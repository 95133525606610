<template>
  <form @submit.prevent="save" v-show="isExpanded" action class="bg-white p-2">
    <div class="d-flex">
      <div class="col">
        <div class="form-row form-group">
          <label for="race">
            <b>Race</b>
          </label>
          <select-input
            class="w-50"
            label="Race"
            ref="race"
            name="race"
            id="race"
            v-model="caseDetails.patientRace"
            :noLabel="true"
            :items="races"
          />
        </div>

        <div class="form-row form-group">
          <label for>
            <b>Middle Name</b>
          </label>
          <text-input
            :noLabel="true"
            class="w-50"
            name="patientMiddleName"
            id="patientMiddleName"
            maxLength="41"
            :validator="$v.caseDetails.patientMiddleName"
            :validatorMsgMap="validatorMsgMap"
            v-model="$v.caseDetails.patientMiddleName.$model"
            data-private="redact"
          />
        </div>
        <div class="form-row form-group">
          <label for="patientTitle">
            <b>Title</b>
          </label>
          <select-input
            name="patientTitle"
            id="patientTitle"
            :noLabel="true"
            class="w-50"
            v-model="caseDetails.patientTitle"
            :items="titles"
          />
        </div>
        <div class="form-row form-group">
          <label for="maidenName">
            <b>Maiden Name</b>
          </label>
          <text-input
            name="maidenName"
            id="maidenName"
            class="w-50"
            :noLabel="true"
            maxLength="41"
            v-model="caseDetails.patientMaidenName"
            :validator="$v.caseDetails.patientMaidenName"
            :validatorMsgMap="validatorMsgMap"
            data-private="redact"
          />
        </div>
        <div class="form-row form-group">
          <label for="patientSuffix">
            <b>Suffix</b>
          </label>
          <select-input
            name="patientSuffix"
            id="patientSuffix"
            :noLabel="true"
            class="w-50"
            v-model="caseDetails.patientSuffix"
            :items="suffixes"
          />
        </div>
      </div>
      <div class="col">
        <div class="form-row form-group">
          <div class="col align-items-center justify-content-between input-group">
            <label class="w-50" for="roomNumber">
              <b>Room #</b>
            </label>
            <text-input
              name="roomNumber"
              id="roomNumber"
              v-model="caseDetails.roomNumber"
              :noLabel="true"
              maxLength="10"
              :validator="$v.caseDetails.roomNumber"
              :validatorMsgMap="validatorMsgMap"
              class="w-50"
            />
          </div>
        </div>
        <div class="form-row form-group">
          <div class="col-7 input-group justify-content-between align-items-center">
            <label for="billingType" class="w-50">
              <b>Billing Type</b>
            </label>
            <SelectInput
              :items="billingTypes"
              class="d-flex align-items-start w-50"
              name="billingType"
              id="billingType"
              v-model="caseDetails.billingType"
            />
          </div>
          <div class="col input-group justify-content-between align-items-center">
            <label class="w-50" for="billingCycle">
              <b>Billing Cycle</b>
            </label>
            <select-input
              class="align-items-start w-50 d-flex"
              name="billingCycle"
              id="billingCycle"
              :dataSource="billingCycles"
              v-model="caseDetails.billingCycle"
            />
          </div>
        </div>
        <div class="form-row form-group">
          <div class="col-7 input-group justify-content-between align-items-center">
            <label class="w-50" for="alternateId">
              <b>Alternate Id</b>
            </label>
            <text-input
              :noLabel="true"
              type="text"
              class="w-50"
              name="alternateId"
              id="alternateId"
              maxLength="31"
              v-model="caseDetails.alternateId"
              :validator="$v.caseDetails.alternateId"
              :validatorMsgMap="validatorMsgMap"
              data-private="redact"
            />
          </div>
        </div>
        <div class="form-row form-group">
          <Pathologist
            class="col"
            id="pathologist"
            :multiple="true"
            name="pathologists"
            v-model="caseDetails.pathologists"
            ref="pathologist"
            accessKey="p"
            label="Pathologists"
          />
        </div>
        <div class="form-row justify-content-center">
          <div class="col-3 form-check">
            <checkbox
              class="form-check-input"
              type="checkbox"
              name="private"
              v-model="caseDetails.private"
              id="private"
              label="Private"
            />
          </div>
          <div class="col-3 form-check">
            <checkbox
              class="form-check-input"
              type="checkbox"
              name="reviewed"
              v-model="caseDetails.reviewed"
              id="reviewed"
              label="Reviewed"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="container mt-4">
      <div class="row d-flex">
        <div class="container col">
          <Address
            ref="addresses"
            class="w-full"
            v-model="caseDetails.addresses"
            :allowEmpty="true"
          />
        </div>
        <div class="container col">
          <phones
            ref="phoneNumbers"
            :v="$v.caseDetails.phoneNumbers"
            v-model="caseDetails.phoneNumbers"
            :items="phoneTypes"
          />
          <emails ref="emails" :v="$v.caseDetails.emails" v-model="caseDetails.emails" />
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end container">
      <button
        v-if="caseDetails.caseId"
        :disabled="!permissions.CaseCreateEdit"
        type="submit"
        class="btn btn-primary"
      >
        Save
      </button>
    </div>
  </form>
</template>

<script>
import Address from "@/components/forms/Address";
import Phones from "@/components/forms/Phones.vue";
import Emails from "@/components/forms/Emails.vue";
import { required, email, maxLength, minLength } from "vuelidate/lib/validators";
import { validatorMsgMapBase } from "@/modules/helpers";
import DropdownService from "@/services/dropdown.js";
import { MaxDiffCollectedAndReceived } from "@/modules/helpers.js";
import { mapState, mapGetters } from "vuex";
import { Pathologist } from "../Selectors";
import SelectInput from "@/components/common/SelectInput.vue";
import TextInput from "@/components/common/TextInput.vue";
import Checkbox from "@/components/common/Checkbox.vue";
import DataSource from "devextreme/data/data_source";

export default {
  name: "DemographicsForm",
  props: {
    accountNumber: Number,
    isExpanded: Boolean,
    value: Object,
    caseDisplay: String,
    validators: Object,
    prefixDefaultPathologistId: Number,
    isSubmitting: Boolean
  },
  components: {
    Address,
    Phones,
    Emails,
    Pathologist,
    SelectInput,
    TextInput,
    Checkbox
  },
  created() {
    this.$store.dispatch("dropdowns/getCasePhoneTypes");
    this.$store.dispatch("dropdowns/getSexes");
    this.$store.dispatch("dropdowns/getRaces");
    this.$store.dispatch("dropdowns/getSuffixes");
    DropdownService.getBillingCycles().then(res => {
      this.billingCycles = new DataSource({
        store: res || [],
        sort: ["displayName"]
      });
    });
    this.$store.dispatch("dropdowns/getBillingTypes");
  },

  computed: {
    ...mapState({
      currentUser: state => state.currentUser,
      MaxDiffCollectedAndReceived: state => state.labSettings.MaxDiffCollectedAndReceived,
      sexes: state => state.dropdowns.sexes,
      races: state => state.dropdowns.races,
      suffixes: state => state.dropdowns.suffixes,
      phoneTypes: state => state.dropdowns.casePhoneTypes,
      billingTypes: state => state.dropdowns.billingTypes,
      defaultBillCycle: state => state.labSettings.DefaultBillCycle
    }),
    ...mapGetters(["permissions"]),

    validatorMsgMap() {
      return validatorMsgMapBase;
    },
    caseDetails: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  data() {
    return {
      pathologists: [],
      pathologistSearch: DropdownService.searchPathologists,
      titles: [],
      billingCycles: []
    };
  },
  validations() {
    return {
      caseDetails: {
        collectedOn: {
          required,
          MaxDiffCollectedAndReceived: value =>
            MaxDiffCollectedAndReceived(value, this.MaxDiffCollectedAndReceived)
        },
        emails: {
          $each: {
            email: { required, email }
          }
        },
        phoneNumbers: {
          $each: {
            phoneNumber: { minLength: minLength(10), maxLength: maxLength(16) }
          }
        },
        patientMiddleName: {
          maxLength: maxLength(40)
        },
        patientMaidenName: {
          maxLength: maxLength(40)
        },
        roomNumber: {
          maxLength: maxLength(9)
        },
        alternateId: {
          maxLength: maxLength(30)
        }
      }
    };
  },
  watch: {
    "caseDetails.pathologists": {
      deep: true,
      handler(value) {
        const anyPrimary = value.filter(e => e.isPrimary);
        if ((!anyPrimary.length && value.length) || anyPrimary.length > 1) {
          this.setPrimaryPathologist(value[0].id);
        }
      }
    },
    isExpanded: {
      handler(nv) {
        if (this.defaultBillCycle && nv && !this.caseDetails.billingCycle) {
          this.caseDetails.billingCycle = this.defaultBillCycle;
        }
      }
    }
  },
  methods: {
    save() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        window.notify("Please verify your input and try again.", "warning");

        return;
      }
      return this.$emit("save");
    },
    setPrimaryPathologist(id) {
      this.caseDetails.pathologists = this.caseDetails.pathologists.map(entry => {
        if (entry?.id === id) {
          entry.isPrimary = true;
        } else {
          entry.isPrimary = false;
        }
        return entry;
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.form-row {
  justify-content: flex-start;
  align-items: center;
}
.form-group {
  justify-content: space-between;
  margin-bottom: 1rem;
  text-align: left;
}
label {
  font-size: 14px;
  margin-bottom: 0;
}
* {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
    "Open Sans", "Helvetica Neue", sans-serif;
}
.addresses {
  overflow-y: scroll;
  max-height: 400px;
}

.header {
  background-color: #e4e4e4;
  padding: 0.5rem 0.7rem;
  margin-bottom: 1rem;
  border-radius: 3px;
  text-align: left;
  align-items: center;
}
.phone,
.email,
.address {
  padding: 0.5rem 1.2rem;
}
.phones-main,
.emails-main {
  overflow-y: auto;
  max-height: 170px;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 5px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }
  /* Handle on hover */
  & ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
w-40 {
  width: 40%;
}
w-30 {
  width: 30%;
}
/* .text-input {
  min-height: 50px !important;
} */
</style>
