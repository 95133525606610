<template>
  <div class="registration-note-form">
    <h2 class="mb-3">Registration Notes</h2>
    <div
      v-for="note of notes"
      v-bind:key="note.specimenIndex"
      class="row d-flex align-items-center"
    >
      <div class="col-1 note-label d-flex justify-content-end">
        <b>{{ note.specimenOrder }}</b>
      </div>
      <div class="col-11"><TextInput :ref="'note' + note.specimenIndex" v-model="note.note" /></div>
    </div>
    <div class="d-flex justify-content-end">
      <button class="btn btn-primary" @click.prevent="handleSubmit">Submit</button>
    </div>
  </div>
</template>

<script>
import { toLetters } from "@/modules/helpers";
import TextInput from "./common/TextInput.vue";
import { mapGetters } from "vuex";
import { SpecimenNumbersEnum } from "@/modules/enums";

export default {
  components: { TextInput },
  props: ["numberOfSpecimens"],
  data() {
    return {
      notes: []
    };
  },
  mounted() {
    this.createNotes();
  },
  computed: {
    ...mapGetters("accessionStore", ["specimenNumbering", "primaryProvider"])
  },
  methods: {
    createNotes() {
      for (let i = 0; i < this.numberOfSpecimens; i++) {
        this.notes.push({
          specimenOrder:
            this.specimenNumbering === SpecimenNumbersEnum.Numbers ? i + 1 : toLetters(i + 1),
          note: "",
          specimenIndex: i
        });
      }
      this.$nextTick(() => {
        this.$refs.note0[0].focus();
      });
    },
    handleSubmit() {
      const notesToSubmit = [];
      for (const note of this.notes) {
        if (note?.note?.length) {
          notesToSubmit.push(note);
        }
      }
      this.$emit("submit", notesToSubmit);
    }
  }
};
</script>

<style lang="scss" scoped>
.registration-note-form {
  width: 95%;
}
.note-label {
  text-align: right;
  padding-right: 0px;
  margin-bottom: 1rem;
}
</style>
