import moment from "moment";

const barcodeRegex =
  /<mm\sln="(\w+)"\sfn="(\w+)"\sdob="([\d]{2}\/[\d]{2}\/[\d]{2,4})"\spid="(\w+)"\smrn="(\w+)"\so="(\w+)"\s.+dos="([\d]{2}\/[\d]{2}\/[\d]{2,4})"\s\/>/is;
/**
 *
 * @param {string} barcodeString
 * @returns
 */
export function emaBarcodeReader(barcodeString) {
  if (barcodeRegex.test(barcodeString)) {
    const match = barcodeString.match(barcodeRegex);
    if (match.length !== 8) {
      return null;
    }
    return {
      patientLastName: match[1],
      patientFirstName: match[2],
      patientDOB: match[3],
      patientAccountNumber: match[4],
      patientMRN: match[5],
      orderNumber: match[6],
      collectedOn: match[7]
    };
  }
  return null;
}

export function parseToDate(dateString, format = "MM/DD/YYYY") {
  const parsedDate = moment(dateString, format);
  if (parsedDate.format("MM/DD/YYYY") === dateString && parsedDate.isValid()) {
    return parsedDate.toDate();
  }
  return dateString;
}
