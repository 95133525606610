<template>
  <form
    ref="accessionForm"
    @submit.prevent="save('quickSave')"
    v-show="isExpanded"
    class="container bg-white px-2 position-relative"
    data-testid="new-case-form"
    v-shortkey="saveShortkeys"
    @shortkey="handleSaveShortkey"
  >
    <div
      class="d-flex flex-wrap align-items-start"
      :class="{ 'justify-content-between': !isManualNumbering }"
    >
      <div class="col-lg-3 col d-flex">
        <prefix
          v-model="caseDetails.labPrefix"
          :validator="$v.caseDetails.labPrefix"
          :validatorMsgMap="validatorMsgMap"
          valueExpr="id"
          :disabled="isAccessioned"
          class="text-input"
          id="prefix"
          name="prefix"
          ref="prefix"
        />
        <icon-button
          v-show="isPrefixAutoNumbering"
          :disabled="isAccessioned || !caseDetails.labPrefix"
          class="text-primary align-self-end ml-1"
          @click="overrideNumberingType"
          icon="info-circle"
          v-tooltip="'Click to override prefix numbering type.'"
        ></icon-button>
      </div>
      <text-input
        v-if="caseDetails.labPrefix && isManualNumbering"
        label="Case #"
        ref="caseNumber"
        class="col-sm col-lg-2"
        v-model="caseDetails.caseNumber"
        @blur="formatAccessionNumber"
        v-focus
        :validator="$v.caseDetails.caseNumber"
        :validatorMsgMap="validatorMsgMap"
        maxLength="13"
        name="caseNumber"
        :disabled="isAccessioned"
      />
      <select-input
        class="col-md ml-md-auto col-lg-2"
        label="Priority"
        data-testid="casePriority"
        :items="priorities"
        searchMode="startswith"
        :tabIndex="2"
        accessKey="y"
        ref="priority"
        name="priority"
        id="priority"
        v-model="caseDetails.priority"
        :validator="$v.caseDetails.priority"
        :validatorMsgMap="validatorMsgMap"
      />
    </div>
    <div class="d-flex flex-wrap mt-1 align-items-start">
      <div class="col-lg-3 d-flex">
        <text-input
          label="HL7 Order#"
          class="flex-1"
          ref="orderNumber"
          id="orderNumber"
          name="orderNumber"
          v-model="caseDetails.orderNumber"
          v-stream:change="orderNumberTrigger$"
          :validator="$v.caseDetails.orderNumber"
          :validatorMsgMap="validatorMsgMap"
          maxLength="500"
          :disabled="hl7loaded || hasManuallyMatched"
        />
        <icon-button
          tabIndex="3"
          class="btn align-self-end mb-2 text-primary"
          type="button"
          v-tooltip="'Open available orders'"
          @click="toggleOrders"
          icon="clipboard-check"
          :disabled="hasManuallyMatched"
          v-if="!hl7loaded"
        />
      </div>
      <date-picker
        label="DOB"
        accessKey="d"
        ref="dob"
        :twoDigitYear="true"
        dateSerializationFormat="yyyy-MM-dd"
        class="col-lg-3 col"
        :max="today"
        id="dob"
        :validator="$v.caseDetails.patientDOB"
        :validatorMsgMap="validatorMsgMap"
        v-model="caseDetails.patientDOB"
        v-stream:blur="blurField$"
        name="dob"
        data-private="redact"
        v-if="arrangementSections[1] === 'patient'"
      />
      <text-input
        label="MRN"
        id="mrn"
        ref="mrn"
        accessKey="m"
        class="col-lg-3 col"
        v-model="caseDetails.patientMRN"
        :validator="$v.caseDetails.patientMRN"
        :validatorMsgMap="validatorMsgMap"
        name="mrn"
        v-stream:blur="blurField$"
        maxLength="31"
        data-private="redact"
      />
      <SsnInput
        id="ssn"
        accessKey="n"
        ref="ssn"
        v-stream:blur="blurField$"
        class="col-lg-3 col"
        :validator="$v.caseDetails.patientSSN"
        :validatorMsgMap="validatorMsgMap"
        v-model="caseDetails.patientSSN"
        name="ssn"
      />
    </div>
    <div v-for="section in arrangementSections" :key="section">
      <div v-if="section === 'specimen'">
        <div class="d-flex flex-wrap align-items-center">
          <number-input
            label="No. of Specimens"
            :validatorMsgMap="validatorMsgMap"
            class="col-lg-3 col"
            type="number"
            ref="numberOfSpecimens"
            accessKey="n"
            name="numberOfSpecimens"
            :max="999"
            id="numberOfSpecimens"
            maxLength="3"
            @keydown.native.prevent.187
            @keydown.native.prevent.189
            :validator="$v.caseDetails.numberOfSpecimens"
            v-model="caseDetails.numberOfSpecimens"
          />
          <date-picker
            label="Received"
            id="received"
            accessKey="e"
            class="col-lg-3 col"
            dateSerializationFormat="yyyy-MM-dd"
            v-model="caseDetails.receivedOn"
            :validator="$v.caseDetails.receivedOn"
            :min="labSettings.AllowReceivedBeforeCollected ? null : caseDetails.collectedOn"
            :max="maxFutureDays"
            :validatorMsgMap="validatorMsgMap"
            name="receivedOn"
            ref="receivedOn"
          />
          <date-picker
            ref="collectedOn"
            label="Collected"
            :min="minCollected"
            :max="labSettings.AllowReceivedBeforeCollected ? maxFutureDays : caseDetails.receivedOn"
            class="col-lg-3 col"
            id="collectedOn"
            dateSerializationFormat="yyyy-MM-dd"
            accessKey="e"
            v-model="caseDetails.collectedOn"
            :validator="$v.caseDetails.collectedOn"
            :validatorMsgMap="validatorMsgMap"
            name="collectedOn"
          />
        </div>
        <div class="d-flex flex-wrap align-items-center">
          <Contact
            label="Providers"
            class="col-lg-9 col-12"
            ref="provider"
            id="provider"
            name="provider"
            accessKey="p"
            trackBy="contactId"
            placeholder="Select Providers"
            :close-on-select="false"
            v-model="caseDetails.contacts"
            :validator="$v.caseDetails.contacts"
            :validatorMsgMap="validatorMsgMap"
          />
        </div>
      </div>
      <div v-if="section === 'patient'">
        <div class="d-flex flex-wrap align-items-start">
          <text-input
            label="Last Name"
            ref="lastName"
            name="patientLastName"
            id="lastName"
            data-cy="patientLastName"
            :validator="$v.caseDetails.patientLastName"
            :validatorMsgMap="validatorMsgMap"
            v-model="caseDetails.patientLastName"
            v-stream:blur="blurField$"
            class="col-md-4"
            maxlengh="41"
            accessKey="l"
            data-private="redact"
          />
          <text-input
            label="First Name"
            name="patientFirstName"
            class="col-md-4"
            id="firstName"
            v-stream:blur="blurField$"
            accessKey="f"
            ref="firstName"
            data-cy="patientFirstName"
            :validator="$v.caseDetails.patientFirstName"
            :validatorMsgMap="validatorMsgMap"
            v-model="caseDetails.patientFirstName"
            maxLength="41"
            data-private="redact"
          />

          <icon-button
            icon="link"
            tabindex="-1"
            type="button"
            @click="$emit('match', 'manual')"
            class="btn text-primary align-self-center icon-button"
            v-tooltip="'Click to open patient matching window.'"
            :disabled="hasManuallyMatched || isOrderLoaded"
          />
        </div>
        <div class="d-flex">
          <date-picker
            label="DOB"
            accessKey="d"
            ref="dob"
            :twoDigitYear="true"
            dateSerializationFormat="yyyy-MM-dd"
            class="col-md-2 col"
            :max="today"
            id="dob"
            :validator="$v.caseDetails.patientDOB"
            :validatorMsgMap="validatorMsgMap"
            v-model="caseDetails.patientDOB"
            v-stream:blur="blurField$"
            name="dob"
            data-private="redact"
            v-if="arrangementSections[0] === 'patient'"
          />
          <gender-selector
            name="sex"
            ref="patientSex"
            class="col-md-2"
            id="sex"
            v-model="caseDetails.patientSex"
            accessKey="r"
          />
          <text-input
            label="Ref #"
            name="refNumber"
            ref="refNumber"
            class="col-md-2"
            id="refNumber"
            v-model="caseDetails.refNumber"
            data-private="redact"
          />
          <text-input
            class="col-md-2"
            label="Account #"
            name="accountNumber"
            id="accountNumber"
            v-model="caseDetails.patientAccountNumber"
            :validator="$v.caseDetails.patientAccountNumber"
            :validatorMsgMap="validatorMsgMap"
            maxLength="31"
            data-private="redact"
          />
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-center">
      <div class="col d-flex align-items-center">
        <div class="">
          <number-input ref="specimenContainer" label="Labels Per Specimen" v-model="labelAmount" />
          <span class="error">This is for containers.</span>
        </div>
        <icon-button
          tabindex="-1"
          v-tooltip="'Reprint labels'"
          @click="toggleReqLabel"
          type="button"
          icon="print"
          class="text-primary btn"
        />
        <modal :status="isReqLabelsOpen" @close="toggleReqLabel"> <PrintLabelsPopup /> </modal>
      </div>
    </div>
    <div class="save-buttons ml-auto">
      <button
        v-tooltip="'Click this to save and continue adding information.'"
        :disabled="isSubmitDisabled"
        v-stream:click.prevent="saveClick$"
        name="advance"
        type="button"
        accesskey="a"
        data-cy="advanceSave"
        class="btn btn-outline-secondary"
      >
        Advance
      </button>
      <button
        v-tooltip="'Click here for quick registration.'"
        :disabled="isSubmitDisabled"
        v-stream:click.prevent="saveClick$"
        name="quickSave"
        data-cy="quickSave"
        data-testid="quickSave"
        class="btn btn-primary ml-2"
      >
        Save
      </button>
    </div>
    <modal :status="isEmaBarcodeConfirmOpen" @close="cancelEmaBarcode">
      <div class="d-flex flex-column barcode-data">
        <h5>Order {{ barcodeInformation.orderNumber }} was not found.</h5>
        <span> The following information was loaded.</span>
        <span>First Name: {{ barcodeInformation.patientFirstName }}</span>
        <span>Last Name:{{ barcodeInformation.patientLastName }}</span>
        <span>DOB: {{ barcodeInformation.patientDOB }}</span>
        <span>MRN: {{ barcodeInformation.patientMRN }}</span>
        <span>Account #: {{ barcodeInformation.patientAccountNumber }}</span>
        <span>Collected: {{ barcodeInformation.collectedOn }}</span>
      </div>
      <div class="mt-2 m-auto" v-if="this.caseDetails.labPrefix && labSettings.AutoAddSpecimens">
        <label>
          <b>No. of Specimens:</b>
        </label>
        <number-input
          v-focus
          :noLabel="true"
          :validatorMsgMap="validatorMsgMap"
          type="number"
          ref="barcodeNumberOfSpecimens"
          name="barcodeNumberOfSpecimens"
          :max="999"
          id="barcodeNumberOfSpecimens"
          maxLength="3"
          @keydown.native.prevent.187
          @keydown.native.prevent.189
          :validator="$v.caseDetails.numberOfSpecimens"
          v-model="caseDetails.numberOfSpecimens"
        />
      </div>
      <div class="my-2 d-flex justify-content-end align-items-center">
        <button class="btn btn-danger" type="button" @click.prevent="cancelEmaBarcode">
          Cancel
        </button>
        <button
          class="btn btn-primary ml-2"
          @click.prevent="confirmEmaBarcode"
          v-shortkey="['enter']"
          @shortkey.prevent="confirmEmaBarcode"
        >
          Confirm
        </button>
      </div>
    </modal>
    <modal :status="isRegistrationNotePopupOpen" @close="handleCloseRegistrationPopup">
      <RegistrationNotePopup
        :numberOfSpecimens="caseDetails.numberOfSpecimens"
        @submit="handleSubmitRegistrationNote"
      />
    </modal>
  </form>
</template>

<script>
import DatePicker from "@/components/common/DatePicker";
import {
  altKey,
  calculateAccessionNumberingWithoutPrevCentury,
  createPatientMatchPayload,
  validatorMsgMapBase
} from "@/modules/helpers.js";
import { mapState, mapGetters } from "vuex";
import { Contact } from "../Selectors";
import Prefix from "../Selectors/Prefix.vue";
import { CasesApi, DropdownApi, Hl7OrdersApi } from "@/services";
import moment from "moment";
import { isEmpty } from "lodash";
import {
  catchError,
  distinctUntilChanged,
  throttleTime,
  filter,
  map,
  switchMap,
  tap,
  withLatestFrom,
  take
} from "rxjs/operators";
import { createRegExFromMatchers } from "@/modules/helpers";
import eventBus, { OPEN_ORDERS } from "@/modules/eventBus";
import { numeric, required, maxLength, minLength, helpers } from "vuelidate/lib/validators";
import {
  SSNValidator,
  maxFutureDaysForReceived,
  MaxDiffCollectedAndReceived
} from "@/modules/helpers.js";
import SelectInput from "@/components/common/SelectInput.vue";
import TextInput from "@/components/common/TextInput.vue";
import NumberInput from "@/components/common/NumberInput.vue";
import PrintLabelsPopup from "@/components/PrintLabelsPopup";
import Modal from "@/components/common/Modal";
import { emaBarcodeReader } from "@/modules/emaBarcodeParser";
import IconButton from "@/components/common/IconButton.vue";
import { AccessioningArrangementEnum, DateFormats } from "@/modules/enums";
import GenderSelector from "@/components/forms/Selectors/GenderSelector.vue";
import SsnInput from "@/components/common/SsnInput.vue";
import RegistrationNotePopup from "@/components/RegistrationNotePopup.vue";

export default {
  name: "AccessionForm",
  components: {
    DatePicker,
    Contact,
    Prefix,
    SelectInput,
    TextInput,
    PrintLabelsPopup,
    Modal,
    NumberInput,
    IconButton,
    GenderSelector,
    SsnInput,
    RegistrationNotePopup
  },
  inject: ["loadedOrder"],
  props: {
    value: Object,
    isExpanded: Boolean,
    isAccessioned: Boolean,
    isOrderLoaded: Boolean,
    order: Object,
    isMatchRunning: Boolean,
    hasPatientMatched: Boolean,
    hasManuallyMatched: Boolean,
    isMatchingOpen: Boolean
  },
  methods: {
    toggleOrders() {
      eventBus.$emit(OPEN_ORDERS);
    },
    async validateCaseNumber(value, vm) {
      if (value?.length === 12 && vm.labPrefix) {
        const [year, number] = value.split("-");
        const payload = {
          prefixId: vm.labPrefix,
          numberYear: year,
          numberSequence: number,
          caseNumber: this.formattedCaseNumber
        };
        const isUsed = await CasesApi.validateCaseNumber(payload);
        if (isUsed) {
          window.notify(`The number ${value} has already been used.`, "warning", 7500);
        }
        return !isUsed;
      }
      return false;
    },
    formatAccessionNumber(event) {
      const { value } = event.target;
      let targetNumber = value.replace(/[^\d]/g, "");
      if (value) {
        if (value.length < 11) {
          const number = calculateAccessionNumberingWithoutPrevCentury(targetNumber);
          if (number?.length === 12) {
            this.caseDetails.caseNumber = number;
          }
        }
        if (value.length === 11 && !value.includes("-")) {
          const caseNumWithOutDash = /([\d]{4})([\d]{7})/;
          if (caseNumWithOutDash.test(value)) {
            this.caseDetails.caseNumber = value.replace(
              caseNumWithOutDash,
              (match, g1, g2) => `${g1}-${g2}`
            );
          }
        }
      }
    },
    overrideNumberingType() {
      if (this.selectedPrefix.numberingType === "Automatic" && this.numberingType !== "Manual") {
        this.numberingType = "Manual";
        window.notify("Numbering set to " + this.numberingType, "warning", 7500);
      } else {
        this.numberingType = null;
        this.caseDetails.caseNumber = 0;
      }
    },
    createDialogHTML(barcodeInformation) {
      const {
        patientFirstName,
        patientLastName,
        patientDOB,
        patientMRN,
        patientAccountNumber,
        orderNumber,
        collectedOn
      } = barcodeInformation;
      return `
      <div class="d-flex flex-column">
      <h5>Order ${orderNumber} was not found. </h5>
      <span> The following information was loaded.</span>
          <span>First Name: ${patientFirstName}</span>
            <span>Last Name: ${patientLastName}</span>
            <span>DOB: ${patientDOB}</span>
            <span>MRN: ${patientMRN}</span>
            <span>Account #: ${patientAccountNumber}</span>
            <span>Collected: ${collectedOn}</span>
      </div>
            `;
    },
    toggleReqLabel() {
      this.isReqLabelsOpen = !this.isReqLabelsOpen;
    },
    selectProviders(data) {
      this.selectedProviders = data;
      if (data.length < this.location.providers.length) {
        return this.location.providers.forEach(provider => {
          const deleted = data.find(e => e.id === provider.id);
          if (!deleted) {
            provider.delete = true;
          }
        });
      }
      this.location.providers = data.map(({ id }) => ({ id }));
    },
    save(event) {
      if (this.isRegistrationNotePopupOpen) {
        this.handleSubmitRegistrationNote();
        return;
      }
      if (
        event === "advance" &&
        this.labSettings.DobIsRequired &&
        !moment(this.value.patientDOB, DateFormats).isValid()
      ) {
        window.alert("Please provide a valid date of birth to proceed.");
        return;
      }
      this.$v.$touch();
      if (this.$v.$invalid) {
        window.notify("Please verify your input and try again.", "warning");
        return;
      } else if (
        event === "quickSave" &&
        this.isRegistrationNotePrefix &&
        !this.hasTriggeredRegistrationNote &&
        this.caseDetails.numberOfSpecimens
      ) {
        this.isRegistrationNotePopupOpen = true;
        this.hasTriggeredRegistrationNote = true;
        return;
      } else {
        if (this.primaryProvider?.defaultPathologistId) {
          const pathologist = this.pathologists.find(
            e => e.id === this.primaryProvider.defaultPathologistId
          );
          if (pathologist) {
            this.caseDetails.pathologists = [{ ...pathologist, isPrimary: true }];
          }
        }
        this.$emit(event, this.value);
        this.hasTriggeredRegistrationNote = false;
        this.$v.$reset();
      }
    },
    confirmEmaBarcode() {
      this.caseDetails.patientFirstName = this.barcodeInformation.patientFirstName;
      this.caseDetails.patientLastName = this.barcodeInformation.patientLastName;
      this.caseDetails.orderNumber = this.barcodeInformation.orderNumber;
      this.caseDetails.collectedOn = this.barcodeInformation.collectedOn;
      this.caseDetails.patientMRN = this.barcodeInformation.patientMRN;
      this.caseDetails.patientDOB = this.barcodeInformation.patientDOB;
      this.caseDetails.patientAccountNumber = this.barcodeInformation.patientAccountNumber;
      this.caseDetails.caseCreationMethodId = 3;
      this.$emit("loadBarcode", this.barcodeInformation);
      this.resetBarcode();
      this.$nextTick(() => {
        const dobInput = this.$refs.dob;
        if (dobInput) {
          dobInput.focus();
        }
      });
    },
    cancelEmaBarcode() {
      this.resetBarcode();
      this.caseDetails.orderNumber = "";
      const orderNumberInput = this.$refs.orderNumber;
      if (orderNumberInput) {
        orderNumberInput.focus();
      }
    },
    resetBarcode() {
      this.isEmaBarcodeConfirmOpen = false;
      this.barcodeInformation = {};
      this.emaBarcodeDialog = "";
    },
    handleSaveShortkey({ srcKey }) {
      this.save(srcKey);
    },
    handleCloseRegistrationPopup() {
      this.isRegistrationNotePopupOpen = false;
      this.save("quickSave");
    },
    handleSubmitRegistrationNote(data) {
      if (data?.length) {
        this.caseDetails.registrationNotes = data.map(e => {
          return { ...e, typeCode: this.registrationNoteId };
        });
      }
      this.handleCloseRegistrationPopup();
    }
  },
  domStreams: ["blurField$", "orderNumberTrigger$", "saveClick$"],
  subscriptions() {
    const orderNumbers$ = this.orderNumberTrigger$.pipe(
      map(({ event: { msg } }) => {
        const newValue = msg.target.value;
        const { OrderNumberMatcher } = this.labSettings;
        const matchers = createRegExFromMatchers(OrderNumberMatcher);
        let match = matchers.find(matcher => matcher.test(newValue));
        if (match) {
          const { orderNumber } = newValue.match(match).groups;
          return orderNumber;
        }
        return newValue;
      }),
      filter(newValue => {
        if (this.loadedOrder() === newValue) {
          return false;
        }
        return Boolean(newValue);
      }),
      switchMap(async newValue => {
        if (newValue === ".") {
          return [];
        }
        return Hl7OrdersApi.searchStore.load({ filter: ["orderNum", newValue] });
      }),
      tap(async data => {
        if (data.length) {
          this.caseDetails.orderNumber = data[0].orderNum;
          eventBus.$emit(OPEN_ORDERS);
        } else if (this.caseDetails.orderNumber === ".") {
          this.caseDetails.orderNumber = "";
          eventBus.$emit(OPEN_ORDERS);
        } else {
          const barcodeInformation = emaBarcodeReader(this.caseDetails.orderNumber);
          if (barcodeInformation) {
            this.barcodeInformation = barcodeInformation;
            this.isEmaBarcodeConfirmOpen = true;
            this.$nextTick(() => {
              if (this.$refs.barcodeNumberOfSpecimens) {
                this.$refs.barcodeNumberOfSpecimens.focus();
                this.$refs.barcodeNumberOfSpecimens.selectAll("barcodeNumberOfSpecimens");
              }
            });
          } else {
            window.notify("No matching orders found.", "warning");
          }
        }
      }),
      catchError((error, s) => {
        return s;
      })
    );
    const patientMatching$ = this.blurField$.pipe(
      filter(({ event }) => {
        let value = "";
        if (event.msg?.target?.value) {
          value = event.msg?.target?.value;
        } else if (event.msg?.event?.originalEvent) {
          value = event.msg.event.originalEvent.target.value;
        }
        return Boolean(value);
      }),
      withLatestFrom(
        this.$watchAsObservable("value", { deep: true }).pipe(
          map(({ newValue }) => {
            const payload = createPatientMatchPayload(newValue);
            const hasPayloadChanged =
              JSON.stringify(payload) !== JSON.stringify(this.lastMatchPayload);
            if (payload && hasPayloadChanged && !this.hasPatientMatched) {
              this.isAwaitingMatch = true;
            } else {
              this.isAwaitingMatch = false;
            }
            this.lastMatchPayload = payload;
            return payload;
          })
        )
      ),
      throttleTime(1000),
      tap(() => {
        this.isAwaitingMatch = false;
      }),
      distinctUntilChanged(),
      tap(payload => {
        if (!isEmpty(payload[1])) {
          this.$emit("match", payload[1]);
        }
      })
    );
    const isSaveEnabled$ = this.$watchAsObservable("isWaitingForSave", { immediate: true }).pipe(
      filter(({ newValue }) => !newValue),
      take(1),
      map(({ newValue }) => !newValue)
    );

    const saveTriggered$ = this.saveClick$.pipe(
      switchMap(() => isSaveEnabled$),
      withLatestFrom(this.saveClick$),
      tap(([isEnabled, { event }]) => {
        if (this.isMatchingOpen || !isEnabled) {
          return;
        }
        const saveType = event.target.name;
        this.save(saveType);
      })
    );
    return {
      orderNumbers$,
      patientMatching$,
      saveTriggered$
    };
  },

  data() {
    return {
      selectedProviders: [],
      filteredProviders: [],
      today: new Date(),
      priorities: [],
      isMultiSelectLoading: false,
      numberingType: null,
      selectedPrefix: null,
      isReqLabelsOpen: false,
      hl7loaded: false,
      arrangementSections: ["specimen", "patient"],
      isAwaitingMatch: false,
      lastMatchPayload: null,
      barcodeInformation: {},
      isEmaBarcodeConfirmOpen: false,
      emaBarcodeDialog: "",
      hasTriggeredRegistrationNote: false,
      isRegistrationNotePopupOpen: false
    };
  },
  validations() {
    return {
      caseDetails: {
        labPrefix: {
          required
        },
        refNumber: {
          maxLength: maxLength(30)
        },
        //! TODO implement an async call to validate that the case number prefix combo is available.
        caseNumber: {
          validCaseNumber: !this.isAccessioned ? helpers.regex("CaseNumber", /^[0-9-]+$/) : true,
          caseNumberValidator:
            this.isManualNumbering && !this.isAccessioned
              ? (value, vm) => (value ? this.validateCaseNumber(value, vm) : true)
              : true,
          minLength: () => !this.isManualNumbering || minLength(12)
        },
        priority: {
          required: value => (this.labSettings.PriorityOptional ? true : helpers.req(value))
        },
        orderNumber: {
          validOrderNumber: value => {
            // Must only be letters, numbers, or hyphens.
            return value ? /^[a-z0-9-]+$/i.test(value) : true;
          },
          maxLength: maxLength(500)
        },
        patientDOB: {
          required: value => (this.labSettings.DobIsRequired ? helpers.req(value) : true),
          maxDateOfBirth: value => {
            if (value) {
              return moment(value, DateFormats).isBefore(moment(new Date()));
            }
            return true;
          },
          DobBeforeCollected: value => {
            if (value && this.caseDetails.collectedOn) {
              return moment(value, DateFormats).isSameOrBefore(
                moment(this.caseDetails.collectedOn)
              );
            }
            return true;
          }
        },

        patientMRN: {
          maxLength: maxLength(30)
        },
        patientSSN: {
          maxLength: maxLength(12),
          minLength: minLength(9),
          SSNValidator() {
            if (this.permissions.SocialSecurityNumberView) {
              return SSNValidator;
            }
            return true;
          }
        },
        numberOfSpecimens: {
          numeric,
          required,
          maxLength: maxLength(3)
        },
        contacts: {
          required,
          onePrimary: value => {
            if (value) {
              const onePrimary = value.find(e => e.isPrimary);
              return Boolean(onePrimary);
            }
            return true;
          }
        },
        receivedOn: {
          required,
          maxFutureDaysForReceived: value =>
            value
              ? maxFutureDaysForReceived(value, this.labSettings?.MaxFutureDaysForReceivedDate)
              : true,
          collectedBeforeReceived: value => {
            const { collectedOn, receivedOn } = this.caseDetails;
            if (
              !this.labSettings.AllowReceivedBeforeCollected &&
              value &&
              collectedOn &&
              receivedOn
            ) {
              return receivedOn >= collectedOn;
            }
            return true;
          }
        },
        collectedOn: {
          required: vm => {
            return !this.selectedPrefix?.collectionDateOptionalAtAccessioning
              ? helpers.req(vm)
              : true;
          },
          MaxDiffCollectedAndReceived: value =>
            value
              ? MaxDiffCollectedAndReceived(
                  value,
                  this.MaxDiffCollectedAndReceivedDates,
                  this.caseDetails.receivedOn
                )
              : true,
          CollectedAfterDob: value => {
            if (value && this.caseDetails.patientDOB) {
              return moment(value, DateFormats).isSameOrAfter(moment(this.caseDetails.patientDOB));
            }
            return true;
          }
        },
        patientFirstName: {
          required,
          maxLength: maxLength(40)
        },
        patientLastName: {
          maxLength: maxLength(40),
          required
        }
      }
    };
  },
  created() {
    DropdownApi.getPriorities().then(res => {
      if (this.DefaultPriority) {
        this.caseDetails.priority = this.DefaultPriority;
      }
      this.priorities = res || [];
    });
    if (this.DefaultPathologistId) {
      DropdownApi.searchPathologists.byKey(this.DefaultPathologistId).then(res => {
        if (res?.id === this.DefaultPathologistId) {
          this.caseDetails.pathologists = [{ ...res, isPrimary: true }];
        }
      });
    }
    this.$store.dispatch("dropdowns/getSexes");
    if (this.labSettings?.AccessioningArrangement === AccessioningArrangementEnum.Grouped) {
      this.arrangementSections = ["patient", "specimen"];
    }
  },
  mounted() {
    //Checks the last prefix used from the vuex store.
    if (this.lastPrefix?.id !== this.selectedPrefix?.id) {
      this.caseDetails.labPrefix = this.lastPrefix.id;
      this.selectedPrefix = this.lastPrefix;
      this.numberingType = this.selectedPrefix.numberingType;
    }
    if (this.$refs?.prefix?.focus) {
      this.$refs.prefix.focus();
    }
    if (!this.pathologists.length) {
      this.$store.dispatch("dropdowns/getPathologists");
    }
  },
  computed: {
    ...mapState({
      labSettings: state => state.labSettings,
      DefaultPriority: state => state.labSettings.DefaultPriority,
      currentLab: state => state.currentLab,
      currentUser: state => state.currentUser,
      sessionDetails: state => state.sessionDetails,
      lastPrefix: state => state.sessionDetails.lastPrefix,
      labelPrintAmount: state => state.sessionDetails.labelPrintAmount,
      requisitionLabels: state => state.requisitionLabels,
      defaultLabelPrinter: state => state.applicationSettings.defaultLabelPrinter,
      DefaultPathologistId: state => state.labSettings.DefaultPathologistId,
      MaxDiffCollectedAndReceivedDates: state => state.labSettings.MaxDiffCollectedAndReceivedDates,
      prefixItems: state => state.dropdowns.prefixes,
      sexes: state => state.dropdowns.sexes,
      pathologists: state => state.dropdowns.pathologists,
      registrationNoteId: state => state.labSettings.RegistrationNoteTagId
    }),
    ...mapGetters(["permissions"]),
    isSubmitDisabled() {
      return (
        this.isAccessioned ||
        (this.isOrderLoaded && !this.labSettings.UseADTOrders) ||
        this.$v.$invalid
      );
    },
    isWaitingForSave() {
      return this.isAwaitingMatch || this.isMatchRunning;
    },
    minCollected() {
      return moment(this.caseDetails.receivedOn, DateFormats)
        .subtract(this.MaxDiffCollectedAndReceivedDates + 1, "days")
        .format("yyyy/MM/DD");
    },
    labelAmount: {
      get() {
        if (typeof this.labelPrintAmount === "number" && !Number.isNaN(this.labelPrintAmount)) {
          return parseInt(this.labelPrintAmount);
        }
        return this.labelPrintAmount;
      },
      set(value) {
        if (value !== this.labelPrintAmount) {
          this.$store.commit("sessionDetails/setLabelPrintOverride", true);
        }
        this.$store.commit("sessionDetails/setLabelPrintAmount", value);
        return value;
      }
    },
    formattedCaseNumber() {
      if (this.caseDetails.caseNumber && this.selectedPrefix) {
        return this.selectedPrefix.code + this.caseDetails.caseNumber;
      }
      return null;
    },
    isPrefixAutoNumbering() {
      if (this.selectedPrefix) {
        return this.selectedPrefix.numberingType === "Automatic";
      }
      return false;
    },
    isManualNumbering() {
      if (this.selectedPrefix && this.numberingType) {
        return this.numberingType === "Manual";
      }
      return false;
    },
    caseDetails: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
        return value;
      }
    },
    maxFutureDays() {
      const today = new Date();
      if (this.labSettings?.MaxFutureDaysForReceivedDate) {
        const maxDay = new Date();
        maxDay.setDate(today.getDate() + this.labSettings.MaxFutureDaysForReceivedDate + 1);
        return maxDay;
      }
      return today;
    },
    validatorMsgMap() {
      return {
        ...validatorMsgMapBase
      };
    },
    saveShortkeys() {
      if (this.isSubmitDisabled) {
        return null;
      }
      return {
        quickSave: altKey("s"),
        advance: altKey("a")
      };
    },
    primaryProvider() {
      if (!this.value.contacts?.length) {
        return null;
      }
      const provider = this.value.contacts.find(e => e.isPrimary);
      return provider?.contact || provider;
    },
    isRegistrationNotePrefix() {
      if (this.selectedPrefix && this.registrationNoteId) {
        return !this.selectedPrefix?.hideRegistrationNoteAtAccession;
      }
      return false;
    }
  },
  watch: {
    "caseDetails.labPrefix": {
      immediate: true,
      handler(nv, ov) {
        if (nv && nv != ov && nv !== this.selectedPrefix?.id) {
          const target = this.prefixItems.find(e => e.id === parseInt(nv));
          if (target) {
            this.selectedPrefix = target;
            if (target?.pathologistId) {
              DropdownApi.searchPathologists.byKey(target.pathologistId).then(res => {
                if (res?.id === target.pathologistId) {
                  this.caseDetails.pathologists = [{ ...res, isPrimary: true }];
                }
              });
            } else if (this.labSettings.DefaultPathologistId) {
              DropdownApi.searchPathologists
                .byKey(this.labSettings.DefaultPathologistId)
                .then(res => {
                  if (res?.id === this.labSettings.DefaultPathologistId) {
                    this.caseDetails.pathologists = [{ ...res, isPrimary: true }];
                  }
                });
            } else if (this.labSettings.PathologistsResetBetweenAccessions) {
              this.pathologists = [];
            }
            this.numberingType = target.numberingType;
            if (target.specLabelCopies && target.specLabelCopies !== this.labelAmount) {
              this.$store.commit("sessionDetails/setLabelPrintAmount", target.specLabelCopies);
            }
            this.caseDetails.private = target.setCaseToPrivate;
            if (this.labSettings.PriorityFocusAfterPrefix && this.$refs?.priority?.focus) {
              this.$refs.priority.focus();
            }
          }
        }
      }
    },
    lastPrefix(nv) {
      if (nv?.numberingType) {
        this.numberingType = nv.numberingType;
      } else {
        this.numberingType = null;
      }
    },
    numberingType() {
      this.caseDetails.caseNumber = "";
    },
    "caseDetails.numberOfSpecimens": function (value, oldValue) {
      if (value?.toString()?.length > 3) {
        return (this.caseDetails.numberOfSpecimens = oldValue);
      }
    },
    "caseDetails.providerId": function (value) {
      this.fetchLocations(value.id);
    },
    "caseDetails.patientFirstName": function (value) {
      const { ForceUpperCasePatientName } = this.labSettings;
      if (ForceUpperCasePatientName) {
        if (parseInt(ForceUpperCasePatientName)) {
          this.caseDetails.patientFirstName = value.toUpperCase();
        }
      }
    },
    "caseDetails.patientLastName": function (value) {
      const { ForceUpperCasePatientName } = this.labSettings;
      if (ForceUpperCasePatientName) {
        if (parseInt(ForceUpperCasePatientName)) {
          this.caseDetails.patientLastName = value.toUpperCase();
        }
      }
    },
    "caseDetails.caseCreationMethodId": function () {
      if ([2, 3].includes(this.caseDetails.caseCreationMethodId)) {
        this.hl7loaded = true;
      } else {
        this.hl7loaded = false;
      }
    },
    isMatchRunning: function (nv, ov) {
      if (!nv && ov) {
        this.isAwaitingMatch = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.form-group {
  align-items: center;
  display: flex;
}
.row {
  align-items: center;
}
label {
  font-weight: 700;
  display: block;
  font-size: 0.75rem;
  margin: 0 0.2rem;
}
.header {
  background-color: #e4e4e4;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  border-radius: 3px;
  text-align: left;
  align-items: center;
}
.info {
  font-size: 0.8rem;
}

.icon-button {
  width: 50px;
  height: 50px;
}
::v-deep #prioritylabel > b > u {
  text-decoration: none;
  border-bottom: 1.5px solid black;
}
::v-deep #mrnlabel,
::v-deep #ssnlabel {
  text-transform: uppercase !important;
}
.error {
  font-size: 0.75rem;
}
.barcode-data {
  font-size: 1.25rem;
  font-weight: 450;
  text-align: center;
}
.save-buttons {
  position: absolute;
  right: 1rem;
  top: 26rem;
}
</style>
